import React, { useState, useEffect } from "react";
import "./qus-ans.scss";
import { Link } from "react-router-dom";
import {
  Doller,
  Mailer,
  PcAssitant,
  Regenarate,
  shareIcon,
  xlsIcon,
} from "../utils/imgPaths";
import * as XLSX from "xlsx";
import AssistantLoader from "../components/ui-component/AssistantLoader";
import InfoIcon from "../assets/imgs/InfoIcon";

const Airesponse = ({ message, isUser, usr_qus, query, dataJson }) => {
  const [content, setContent] = useState([]);
  const [hasTable, setHasTable] = useState(false);
  const apiResponse = message;

  useEffect(() => {
    const parseResponse = (response) => {
      const sections = response.split("\n\n");
      const parsedContent = sections.map((section) => {
        if (section.includes("|")) {
          return parseTable(section);
        } else {
          return { type: "text", content: section };
        }
      });
      const tableFound = parsedContent.some((item) => item.type === "table");
      setHasTable(tableFound);
      return parsedContent;
    };

    const parseTable = (tableText) => {
      const lines = tableText
        .split("\n")
        .filter((line) => line.trim().length > 0);

      let headers = [];
      const tableData = [];

      for (let i = 0; i < lines.length; i++) {
        const row = lines[i]
          .split("|")
          .map((col) => col.trim())
          .filter(
            (col, index, arr) =>
              !(col === "" && (index === 0 || index === arr.length - 1))
          );

        if (i === 1) continue;

        if (
          row.every(
            (cell) =>
              cell === "" || cell === "..." || cell.match(/^(\s*\.\s*)+$/)
          )
        ) {
          continue;
        }

        if (i === 0) {
          headers = row;
        } else if (row.length === headers.length) {
          const rowData = {};
          headers.forEach((header, index) => {
            rowData[header] = row[index] || "";
          });
          tableData.push(rowData);
        }
      }

      return { type: "table", headers, rows: tableData };
    };
    setContent(parseResponse(apiResponse));
    // console.log((usr_qus && usr_qus !==undefined )&& usr_qus)
  }, [message]);

  const downloadExcel = (usr_qus) => {
    const tableData = content?.filter((item) => item.type === "table");
    const introText = content?.find((item) => item.type === "text")?.content;
    if (tableData.length === 0) {
      console.error("No table data to export.");
      return;
    }

    const workbook = XLSX.utils.book_new();

    tableData.forEach((table, index) => {
      const tableArray = [];

      tableArray.push([introText || ""]);

      tableArray.push([]);

      tableArray.push(table.headers);
      table.rows.forEach((row) => {
        tableArray.push(table.headers.map((header) => row[header] || ""));
      });

      const worksheet = XLSX.utils.aoa_to_sheet(tableArray);

      const colWidths = table.headers.map((header, colIndex) => {
        const maxLength = Math.max(
          ...tableArray.map((row) =>
            row[colIndex] ? row[colIndex].length : 10
          )
        );
        return { wch: maxLength + 5 };
      });
      worksheet["!cols"] = colWidths;

      Object.keys(worksheet).forEach((cell) => {
        if (
          worksheet[cell] &&
          typeof worksheet[cell] === "object" &&
          !cell.startsWith("!")
        ) {
          worksheet[cell].s = {
            font: { name: "Arial", sz: 12 },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "left",
            },
          };
        }
      });

      table.headers.forEach((header, headerIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ c: headerIndex, r: 2 });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            font: { bold: true, name: "Arial", sz: 12 },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        }
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, `Table-${index + 1}`);
    });

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "table_data.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const BottomButtons = ({ imgPath, isDisable, hasFunc, hasTable }) => (
    <button
      disabled={isDisable}
      className={`btns-5 ms-1 ${
        isDisable === true || hasTable === false ? "isDisabled" : ""
      }`}
      onClick={hasFunc ? downloadExcel : () => console.log("hits")}
    >
      <img src={imgPath} alt="" className="" height={22} width={22} />
    </button>
  );

  const renderContent = (content) => {
    return content?.map((item, index) => {
      if (item?.type === "text") {
        return (
          <p key={index}>
            {item?.content === "Assistant is typing" ? (
              <AssistantLoader />
            ) : (
              item?.content
            )}
          </p>
        );
      } else if (item?.type === "table") {
        return (
          <table border="1" cellPadding="5" key={index}>
            <thead>
              <tr>
                {item.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {item.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {item.headers.map((header, colIndex) => (
                    <td key={colIndex}>{row[header]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      return null;
    });
  };

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        // console.log(text);
      })
      .catch(err => {
        // Optional: Handle errors
        console.error('Failed to copy: ', err);
      });
  }
  return (
    <>
      <div className={`${isUser ? "mb-2" : "mb-2 "}`}>
        <div className={`${isUser ? "qus-text ms-auto mb-4" : "mb-2 d-flex"}`}>
          {!isUser && (
            <img
              src={PcAssitant}
              alt=""
              className="img-rounded border rounded-3 me-2 p-2"
              height={48}
              width={48}
            />
          )}
          <div className={`${isUser ? "" : "mb-2"}`}>
            <div className={`${isUser ? "" : "res-text mb-2"}`}>
              {renderContent(content)}
            </div>

            {!isUser && message !== "Assistant is typing" && (
              <div className="text-end mb-3 ">
                <p className="mb-2 fs-14">
                  See more @
                  <Link
                    to={"https://www.pharmacompass.com/"}
                    target="_blank"
                    rel="noreferer noopener"
                  >
                    PharmaCompass
                  </Link>
                  <span className={`ms-2 ${query === null? 'd-none': ''}`}>
                    <button className="bg-transparent border-0 p-0 cursor-pointer" onClick={()=>copyToClipboard(query)} title={query}>
                    <InfoIcon/>
                    </button>
                  </span>
                </p>
                <div className="d-flex justify-content-end">
                  <button disabled={true} className={`btns-5 ms-1 isDisabled`}>
                    <img
                      src={Regenarate}
                      alt=""
                      className=""
                      height={22}
                      width={22}
                    />
                  </button>
                  <BottomButtons imgPath={shareIcon} isDisable={true} />
                  <Link
                    to={`mailto:support@pharmacompass.com?subject=${encodeURIComponent(
                      usr_qus
                    )}&body=${encodeURIComponent(message)}`}
                  >
                    <BottomButtons imgPath={Mailer} isDisable={true} />
                  </Link>
                  <div className={`${hasTable ? "hide" : ""}`}>
                    <BottomButtons
                      imgPath={xlsIcon}
                      isDisable={false}
                      hasFunc={true}
                      hasTable={hasTable}
                    />
                  </div>
                  <BottomButtons imgPath={Doller} isDisable={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Airesponse;
