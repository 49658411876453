import React, { useState, useEffect } from "react";
import "./layout.scss";
import SingleAd from "./SingleAd";
// import SideMenu from "./SideMenu";
import { Help, Hut, pill } from "../utils/imgPaths";
import ExpandCollapse from "./ExpandCollapse";
import QusAns from "../chat-area/QusAns";
import Header from "./Header";
import { Link } from "react-router-dom";
import Login from "./Auth/Login";
import Tooltip from "./ui-component/Tooltip";
const Layout = () => {
  const BASEURL = "https://www.pharmacompass.com";
  const [history, setHistory] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [clickedId, setClickedId] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getChatHistory = async () => {
    const url = "https://api.pharmacompass.ai/get_chat_history/";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage?.getItem("email"),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonResponse = await response.json();
      setHistory(jsonResponse.chat_history);
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };
  const historyChat = async (chatId, index) => {
    if (chatId !== undefined) {
      setClickedId(chatId);
      setActiveIndex(index);
      const url = `https://api.pharmacompass.ai/getchatdetails/`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        setChatData(jsonResponse);
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    }
  };

  const getSessionData = () => {
    const userData = localStorage.getItem("user");
    const expiration = localStorage.getItem("expiration");
    const now = new Date().getTime();

    if (expiration && now > expiration) {
      localStorage.removeItem("user");
      localStorage.removeItem("expiration");
      localStorage.removeItem("sessionId");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("client");
      localStorage.removeItem("level");
      return null;
    }

    return userData ? JSON.parse(userData) : null;
  };

  useEffect(() => {
    const userLoggedIn = localStorage?.getItem("email");
    getSessionData();
    if (userLoggedIn) {
      getChatHistory();
      historyChat();
    }
  }, [isLoggedIn]);

  const SiteUrls = ({ urlName, linkName, tooltip }) => (
    <Tooltip content={tooltip} position="bottom-right">
      <Link
        to={`${BASEURL}/${urlName}`}
        className="text-decoration-none inherit-color mb-0 d-flex align-items-center me-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={pill} alt="" className="me-2" height={16} width={16} />
        <p className="mb-0">{linkName}</p>
      </Link>
    </Tooltip>
  );

  return (
    <>
      <main className="ai-pc d-flex px-3 w-100">
        <aside className="ai-query-history pe-3">
          <div className="" style={{ height: 74 }}></div>
          <div className="side-menu-link">
            <Link
              to={`/`}
              className="text-decoration-none inherit-color mb-0 d-flex align-items-center pc-bg rounded-4 px-2 me-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Hut} alt="" className="me-2" height={16} width={16} />
              <p className="mb-0">{`Home`}</p>
            </Link>

            <SiteUrls
              urlName={`find-suppliers`}
              linkName={`API/FDF`}
              tooltip={`Find Suppliers, API Ref. Price, FDF Dossiers, Global sales and patents, Intermediate Suppliers`}
            />
            <SiteUrls
              urlName={`pharma-services`}
              linkName={`Services`}
              tooltip={`Find Services Providers`}
            />
            <SiteUrls
              urlName={`pharma-excipients`}
              linkName={`Excipients`}
              tooltip={`Find Excipients Manufacturers`}
            />
            <SiteUrls
              urlName={`pipeline-prospector-drugs-in-development`}
              linkName={`Drugs in Dev.`}
              tooltip={`Check Deals & Pipeline Updates`}
            />
            <SiteUrls
              urlName={`pharma-and-biotech-news`}
              linkName={`News`}
              tooltip={`Scan Latest Pharma & Biotech News`}
            />
            <SiteUrls
              urlName={`blog`}
              linkName={`Blog`}
              tooltip={`Gain invaluable market insights`}
            />

            <div className="search-history">
              {localStorage?.getItem("email") ? (
                <ExpandCollapse title="Search History">
                  {history?.map((query, index) => (
                    <p
                      key={index}
                      className={`mb-0 ps-2 text-truncate mb-0 pe-0 cursor-pointer me-3 ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => historyChat(query?.chat_id, index)}
                      title={query?.chat_name}
                    >
                      {query?.chat_name}
                    </p>
                  ))}
                </ExpandCollapse>
              ) : (
                <div className="px-3 py-3 mb-3 me-3 help-center">
                  <h4 className="mb-0 p-0 d-flex mb-1">
                    <img
                      src={Help}
                      alt=""
                      className="me-1"
                      height={18}
                      width={18}
                    />
                    Help center
                  </h4>
                  <h4 className="mb-0 p-0 ">
                    <Link
                      to={`https://www.pharmacompass.com/terms-and-conditions`}
                      target="_blank"
                      className="text-decoration-none me-1"
                    >
                      Terms of use
                    </Link>
                    and
                    <Link
                      to={`https://www.pharmacompass.com/privacy-policy`}
                      target="_blank"
                      className="text-decoration-none ms-1"
                    >
                      Privacy Policy
                    </Link>
                  </h4>
                </div>
              )}
            </div>
          </div>
        </aside>
        <div className="ai-responses-area border-start border-end">
          <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          <QusAns chatData={chatData} clickedId={clickedId} />
        </div>
        <aside className="ads-view ps-3" aria-label="advertisements">
          <SingleAd
            url={
              "https://www.pharmacompass.com/image/services/abbvie-contract-manufacturing-23768.gif"
            }
            Vburl={`https://www.pharmacompass.com/pharma-services/api-manufacturing-services/abbvie-contract-manufacturing`}
          />
          <SingleAd
            url={
              "https://www.pharmacompass.com/image/services/suanfarma-45403.gif"
            }
            Vburl={`https://www.pharmacompass.com/pharma-services/api-fdf-drug-development/suanfarma`}
          />
          <SingleAd
            url={
              "https://www.pharmacompass.com/image/services/bachem-ag-74460.gif"
            }
            Vburl={`https://www.pharmacompass.com/pharma-services/api-manufacturing-services/bachem-ag`}
          />
        </aside>
      </main>
      {/* <footer>
        <p>All Rights are reserved</p>
      </footer> */}
      {isLoggedIn && (
        <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      )}
    </>
  );
};
export default Layout;
