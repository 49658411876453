import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Airesponse from "./AiResponse";
import { send } from "../utils/imgPaths";

const QusAns = ({ chatData, clickedId }) => {
  const [prompt, setPrompt] = useState("");
  const [chatId, setChatId] = useState("");
  const [messages, setMessages] = useState([]);
  const [msgJson,setMsgJson] =useState();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeArea, setActiveArea] = useState(true);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useLayoutEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    };
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const userLoggedIn = !!localStorage?.getItem("email");
    setActiveArea(!userLoggedIn || isFetching);
  }, [isFetching]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        setShowScrollToBottom(scrollTop < scrollHeight - clientHeight - 400);
      }
    };
    const container = chatContainerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (chatData) {
      const updatedMessages = chatData
        ?.map((msg) => [
          { text: msg.user_question, isUser: true },
          { text: msg.assistant_response, isUser: false, usr_qsn: msg?.user_question,query: msg?.query },
        ])
        .flat();
      setMessages(updatedMessages);
      // console.log('message',updatedMessages)
      setChatId(chatData?.clickedId ? chatData?.clickedId : chatId);
      setMsgJson(chatData)
    }
  }, [chatData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt) {
      alert("Please provide a prompt.");
      return;
    }
    
    setPrompt("");
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: prompt, isUser: true },
      { text: "Assistant is typing", isUser: false, isTyping: true, usr_qsn: prompt },
    ]);

    setIsFetching(true);

    try {
      const res = await fetch("https://api.pharmacompass.ai/chat/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage?.getItem("email"),
          prompt: prompt,
          chat_id:
            clickedId && clickedId !== undefined
              ? clickedId
              : !clickedId && chatId
              ? chatId
              : "",
        }),
      });

      const data = await res.json();
      console.log('data',data)
      setMsgJson(data);
      setChatId(data?.chat_id);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { text: data?.assistant, isUser: false,query:data?.query },
      ]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { text: "Error fetching response", isUser: false },
      ]);
    } finally {
      setIsFetching(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="messages-container ai-responses px-3 pt-3"
        ref={chatContainerRef}
      >
        {messages?.map((msg, index) => (
          <Airesponse
            key={index}
            message={msg?.text}
            usr_qus={msg?.usr_qsn}
            query={msg?.query}
            isUser={msg?.isUser}
            triggerFunction={handleSubmit}
            clickedId={clickedId}
            dataJson={msgJson}
          />
        ))}
        <div className="mt-5" ref={messagesEndRef} />
      </div>

      {/* scroll-to bottom start */}
      {showScrollToBottom && (
        <button
          onClick={scrollToBottom}
          className="back-to-bottom cursor-pointer"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 6L8 11L13 6" stroke="black" stroke-linecap="round" />
          </svg>
        </button>
      )}
      {/* scroll-to bottom ends */}

      <div className="d-flex user-query-n">
        <form
          id="user-query"
          onSubmit={handleSubmit}
          className="d-flex w-100 d-block"
        >
          <textarea
            className={activeArea ? "cursor-no-drop" : ""}
            placeholder={`Ask PharmaCompass.AI`}
            value={prompt}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={activeArea}
          ></textarea>
          <button
            className="border-0 bg-transparent border-top cursor-pointer"
            type="submit"
            disabled={activeArea}
          >
            <img
              src={send}
              alt=""
              className={activeArea ? "disabled-field" : ""}
              height={34}
              width={36}
            />
          </button>
        </form>
      </div>
    </>
  );
};

export default QusAns;
