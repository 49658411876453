import React, { useState } from 'react';
import './ui-style.scss';

const Tooltip = ({ children, content, position }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      className="tooltip-container w-100"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={{ display: 'block', position: 'relative' }}
    >
      {children}
      {visible && (
        <div className={`tooltip-box tooltip-${position}`}>
          {content}
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'bottom-right',
};

export default Tooltip;