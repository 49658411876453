import React from "react";
import { Link } from "react-router-dom";
const SingleAd = (props) => {
  return (
    <div className="my-3">
      <Link to={props?.Vburl} target="_blank" rel="noopener noreferrer">
        <img
          alt=""
          src={props?.url}
          className="img-responsive ad-img"
          height={192}
          width={192}
        />
      </Link>
      <div className="d-flex justify-content-between">
        <p className="mb-0">
          <Link to={props?.Vburl} target="_blank" className="inherit-color text-decoration-none">Virtual Booth</Link>
        </p>
        <p>|</p>
        <p className="mb-0 isDisabled">
          <Link to={'#'} className="inherit-color text-decoration-none">Contact</Link>
        </p>
      </div>
    </div>
  );
};
export default SingleAd;
