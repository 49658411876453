import React, { useEffect, useState } from "react";
import { HistoryIcon } from "../utils/imgPaths";

const ExpandCollapse = ({ children, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expand-collapse text-truncate d-flex flex-column-reverse">
      <button onClick={toggleExpand} className="expand-button px-0 mb-0 d-flex align-items-center">
        <span className="pt-1 me-2">
          <img
            src={HistoryIcon}
            alt="search icon"
            className=""
            height={16}
            width={16}
          />
        </span>
        {title} {isExpanded ? "▼" : "▲"}
      </button>
      <div
        className="expand-content"
        style={{
          maxHeight: isExpanded ? "400px" : "0",
          opacity: isExpanded ? "1" : "0",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandCollapse;
